<template>
  <div class="">
    <div class="row">
      <div class="container">
        <div class="large-12 medium-12 small-12 cell">
          <b-form-file
              v-model="file"
              :state="Boolean(file)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-on:change="handleFileUpload"
              accept=".csv"
          ></b-form-file>
          <button v-on:click="submitFile">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from 'axios';
export default {
  name: "import",
  data() {
    return {
      file: ''
    }
  },
  methods: {
    submitFile(){
      this.$set(this.adminHeaders, 'Content-Type', 'multipart/form-data');
      console.log("adminHeaders");
      console.log(this.adminHeaders);
      let formData = new FormData();
      formData.append('file', this.file);
      axios.post( this.basePath+'api/admin/import_data', formData, {headers: this.adminHeaders})
          .then(function(response){
            console.log('SUCCESS!!');
            console.log(response);
          })
          .catch(function(response){
            console.log('FAILURE!!');
            console.log(response);
          });
      //this.$set(this.adminHeaders, 'content-type', 'application/json');
    },
    handleFileUpload(e){
      this.file = e.target.files[0];
    }
  },
  mounted(){
    //
  },
  computed: {
    //
  }
}
</script>

<style scoped>

</style>